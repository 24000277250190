*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}
.heading{
    position: relative;
    justify-content: left;
    font-family: Ubuntu;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.015em;
        text-align: left;
        background: linear-gradient(87.04deg, #0077AC 33.1%, #33AB51 75.79%);
        -webkit-background-clip: text;
        color: transparent;
}

.knwbtn{
    width: Fixed (280px) ;
    height: Hug (56px) ;
    padding: 15px 68px 16px 56px;
    border-radius: 8px;
     gap: 10px;
    background: linear-gradient(87.04deg, #0077AC 33.1%, #33AB51 75.79%);
    color: aliceblue;
    border: none;
    
}
.about{
    /* width: Fixed (1, 145px);
     height: Hug (183px); */
     position: absolute;
      top: -450px;
       left: -1072px;
        gap: 32px;
        background: linear-gradient(112.49deg, #01204C 2.86%, #1A6CE0 87.34%);
        color: aliceblue;
}


.homepage-about-section{
width: Fixed (1, 440px);
height: Hug (301px);
 padding: 33px 93px 19px 97px;
gap: 32px;
background: linear-gradient(87.4deg, #0077AC 44.7%, #33AB51 56.08%);
}
.about-line{
    flex: 1;
    width: 10px;
    height: 8px; 
    padding-right: 10px;
    border-radius: 2px;
    background: linear-gradient(87.4deg, #0077AC 34.7%, #33AB51 46.08%);
    display: inline-block;
        
}
.about-heading{
    font-family: Ubuntu;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        width: 1384px; 
        height: 37px;
        white-space: nowrap;
}

.row {
    display: flex;
    align-items: center;
}

.about-content{
    /* font-family: Ubuntu !important; */
        font-size: 20px;
        font-weight: 400 !important;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: justified;
        color: #FFFFFF;;
}
.our-mission{
    width: auto; 
    height: Hug (350px); 
    justify: space-between;
    padding-left: 6%;
}

.our-mission-content{
    width: auto; 
    height: Hug (131px); 
    gap: 16px;
}

.our-mission-heading{
    font-family: Ubuntu;
        font-size: 50px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: 0em;
        text-align: right;
        color: #3193EB;
}
.left-aligned-image{
            display: block;
            float: left;
            /* margin-left: 110px; */
            align-items: end;
        
}
@media (max-width: 760px) {
    .left-aligned-image {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}
.testimonials {
    
    justify-content: center;
    align-items: center;
}

.centered-paragraph {
    text-align: center;
}

.testimonials-heading{
font-family: Ubuntu;
font-size: 24px;
font-weight: 700;
line-height: 28px;
letter-spacing: 0em;
text-align: center;

}
.testimonials {
    text-align: center;
    /* Center-align the content horizontally */
}

.centered-paragraph {
    text-align: center;
}

.testimonials-heading {
    text-align: center;
}
.work_with_best_practice{
    width: Fill (1, 440px); 
    height: Hug (430px); 
    padding: 0px 130px 0px 130px; 
    justify-content: space-between;
}
.finsara{
    font-family: Ubuntu;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #00C077;
}
.finsara-heading {
    font-family: Ubuntu;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(67.4deg, #0077AC 37.7%, #33AB51 26.08%);
}
.finsara-text{
    font-family: Ubuntu;
        font-size: 20px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #414141;
}

.product-page-header {
    width: 100%;
    height: auto;
    padding: 46px 150px;
    background-image: url(../images/Group_41052.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
}
.p-heading{
    font-family: Ubuntu;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: -0.015em;
        text-align: center;
background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(87.4deg, #0077AC 44.7%, #33AB51 56.08%);
}

.product-page-section .card-title{
    font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: #3193EB;
}
.why-finsara-section .card-title {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #3193EB;
}
.icon-image {
    margin: 0 auto;
    display: block;
}
.faq-section{
    width: 70%; 
    height: Hug (404px); 
    gap: 16px;
   margin: auto;
}
.faq-heading{
    font-family: Ubuntu;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #414141;
}
.who-we-are-header{
            width: 100%;
            height: auto;
            padding: 46px 150px;
            background-image: url(../images/Group_41053.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            opacity: 0.9;       
}
.member-name{
    font-family: Ubuntu;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
color: #3193EB;
}
.member-degination{
font-family: Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #5C5C5C;
}
.description{
    font-family: Ubuntu;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #5C5C5C;
}
.management-team-section {
        width: 100%;
        height: Hug (301px);
        padding: 12px 130px 0px 130px;
        gap: 32px;
        background: linear-gradient(87.4deg, #0077AC 44.7%, #33AB51 56.08%);
}
.contact-heading{
    font-family: Ubuntu;
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: -0.015em;
        text-align: left;
background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image:linear-gradient(67.4deg, #0077AC 20.7%, #33AB51 30.08%);
}
.agreement{
font-family: Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
color:#414141;
    }
.nav-link.active {
    position: relative;
}

.nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(87.4deg, #0077AC 44.7%, #33AB51 56.08%);
}

.policies-header {
    width: 100%;
    height: auto;
    padding: 46px 150px;
    background-image: url(../images/Group_41054.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
}
.tab-button{
    width: 100%;
        height: Hug (56px);
        padding: 9px 54px 16px 56px;
        border-radius: 8px;
        gap: 10px;
        background: linear-gradient(87.04deg, #0077AC 33.1%, #33AB51 75.79%);
        color: aliceblue;
}

/* Custom styles for the tab buttons */
.nav-tabs .nav-link.active {
    background: linear-gradient(87.04deg, #0077AC 33.1%, #33AB51 75.79%);
    color: aliceblue;
}

.nav-tabs .nav-link {
    background-color: white;
    color: black;
}

.centered-paragraph-corevalue{
    text-align: center;
    font-family: Ubuntu;
        font-size: 50px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: 0em;
        text-align: center;
        color:#3193EB
}
.finsara_logo{
    width: Hug (1, 440px); 
    height: Fixed (3, 018px); 
    top: 591px; 
    gap: 72px
}

.our-partnership-heading {
    font-family: Ubuntu;
    font-size: 32px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(67.4deg, #0077AC 17.7%, #33AB51 26.08%);
}
.homepage-knowmore-btn{
    font-family: Ubuntu;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color: #414141;
}
.navbar{
    background-color:#FFFFFF;
    
}

